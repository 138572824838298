// src/components/PrivacyPolicy.tsx

import React from 'react';
import '../styles/PrivacyPolicy.scss';

const PrivacyPolicy: React.FC = () => {
  return (
    <div className="privacy-policy-page">
      <h2>Privacy Policy</h2>
      <p>Welcome to Dark Country! This Privacy Policy describes how we collect, use, and disclose your information when you play our mobile card game. By accessing or using our game, you agree to the practices described in this policy.</p>

      <h3>1. Information We Collect</h3>
      <p>We may collect certain types of information, such as:</p>
      <ul>
        <li><strong>Personal Information:</strong> Including username, email address, and other details required for account creation and gameplay.</li>
        <li><strong>Gameplay Data:</strong> Information about your in-game activity, such as level progress, game achievements, and other gameplay statistics.</li>
        <li><strong>Device Information:</strong> Device type, operating system, unique device identifiers, and IP address, to improve game performance and user experience.</li>
      </ul>

      <h3>2. How We Use Your Information</h3>
      <p>We use your information to provide, personalize, and improve the Dark Country experience. This includes:</p>
      <ul>
        <li>Maintaining game functionality and performance</li>
        <li>Providing customer support and resolving issues</li>
        <li>Analyzing gameplay data to enhance features and content</li>
        <li>Sending updates, notifications, and promotional content (you can opt out at any time)</li>
      </ul>

      <h3>3. Sharing Your Information</h3>
      <p>We do not sell your personal data to third parties. However, we may share data with trusted partners, such as:</p>
      <ul>
        <li><strong>Service Providers:</strong> For analytics, hosting, and customer support to help us operate the game efficiently.</li>
        <li><strong>Legal Purposes:</strong> If required to comply with legal obligations or to protect our rights and users' safety.</li>
      </ul>

      <h3>4. Data Security</h3>
      <p>We prioritize protecting your data and implement industry-standard security measures to safeguard it from unauthorized access, alteration, or destruction.</p>

      <h3>5. Changes to this Policy</h3>
      <p>We may update this Privacy Policy periodically. Any changes will be posted here with the "last updated" date at the top of this page. Please check back regularly for updates.</p>

      <h3>6. Contact Us</h3>
      <p>If you have any questions about this Privacy Policy, please contact us at support@darkcountry.com.</p>
    </div>
  );
};

export default PrivacyPolicy;
