// src/components/Footer.tsx

import React from 'react';
import '../styles/Footer.scss';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const Footer: React.FC = () => {
  const location = useLocation();


    // Check if the current path is '/privacy-policy'
    const isPrivacyPolicyPage = location.pathname === '/privacy-policy';


  return (
    <footer className="footer">
      <Link to={isPrivacyPolicyPage ? "/" : "/privacy-policy"} className="footer-link">
        {isPrivacyPolicyPage ? "Home" : "Privacy policy"}
      </Link>
      <p>© Dark Country, 2024</p>
    </footer>
  );
};

export default Footer;
